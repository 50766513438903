// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';

function TradingViewMarketData() {
  const container = useRef();

  useEffect(
    () => {
      const script = document.createElement("script");
      //script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
      script.src = "https://cryptoaitrend.com/scripts/misc/embed-widget-market-quotes.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
            "width": "100%",
            "height": "100%",
            "symbolsGroups": [
            {
                "name": "Indices",
                "originalName": "Indices",
                "symbols": [
                {
                    "name": "BINANCE:BTCUSD"
                },
                {
                    "name": "BINANCE:ETHUSD"
                },
                {
                    "name": "BINANCE:SOLUSD"
                },
                {
                    "name": "BINANCE:BNBUSD"
                },
                {
                    "name": "BINANCE:XRPUSD"
                }
                ]
            },
            {
                "name": "Futures",
                "originalName": "Futures",
                "symbols": [
                {
                    "name": "CME_MINI:ES1!",
                    "displayName": "S&P 500"
                },
                {
                    "name": "CME:6E1!",
                    "displayName": "Euro"
                },
                {
                    "name": "COMEX:GC1!",
                    "displayName": "Gold"
                },
                {
                    "name": "NYMEX:CL1!",
                    "displayName": "WTI Crude Oil"
                }
                ]
            }
            ],
            "showSymbolLogo": true,
            "isTransparent": false,
            "colorTheme": "light",
            "locale": "en",
            "backgroundColor": "#ffffff"
        }`;
      container.current.appendChild(script);
    },
    []
  );

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
     
    </div>
  );
}

export default memo(TradingViewMarketData);