// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';

function TradingViewHeatMap() {
  const container = useRef();

  useEffect(
    () => {
      const script = document.createElement("script");
      //script.src = "https://s3.tradingview.com/external-embedding/embed-widget-crypto-coins-heatmap.js";
      script.src = "https://cryptoaitrend.com/scripts/misc/embed-widget-crypto-coins-heatmap.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "exchanges": [],
          "dataSource": "Crypto",
          "grouping": "sector",
          "blockSize": "market_cap_basic",
          "blockColor": "change",
          "locale": "en",
          "symbolUrl": "",
          "colorTheme": "light",
          "hasTopBar": false,
          "isDataSetEnabled": false,
          "isZoomEnabled": false,
          "hasSymbolTooltip": true,
          "isMonoSize": false,
          "width": "100%",
          "height": "100%"
        }`;
      container.current.appendChild(script);
    },
    []
  );

  return (
    <div className="tradingview-widget-container" ref={container} style={{ 
      overflow: 'hidden', 
      pointerEvents: 'none', // disables scroll and other interactions inside the widget
      }}
      >
      <div className="tradingview-widget-container__widget"></div>
     
    </div>
  );
}

export default memo(TradingViewHeatMap);
