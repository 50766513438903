import React from 'react';
import ReactDOM from 'react-dom';
import TradingViewSymbols from './TradingViewSymbols';
import TradingViewHeatMap from './TradingViewHeatMap';
import TradingViewMarketData from './TradingViewMarketData';

const Symbol = () => (

    <TradingViewSymbols />

);

const HeatMap = () => (

    <TradingViewHeatMap />

);

const MarketData = () => (

    <TradingViewMarketData />

);


ReactDOM.render(<Symbol />, document.getElementById('symbolOverview'));
ReactDOM.render(<HeatMap />, document.getElementById('heatMap'));
ReactDOM.render(<MarketData />, document.getElementById('marketData'));